import React, { useState } from "react";
import HospitalBooking from "../hospital-booking/HospitalBooking";
import DoctorDetail from "../doctor-detail/DoctorDetail";
import AppointmentDetails from "../appointment-details/AppointmentDetails";
import BookingCalendar from "../booking-calendar/BookingCalendar";
import { useNavigate } from "react-router-dom";
import HospitalDetails from "../hospital-details/HospitalDetails";

const HospitalPagesNavigate = () => {
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null); // Add this line
  const [view, setView] = useState("hospitalBooking");

  const navigate = useNavigate();

  const handleHospitalCardClick = (hospital) => {
    console.log("Selected hospital data:", hospital);
    setSelectedHospital(hospital);
    navigate(`/app/booking/hospital-details/${hospital.phone}`, {
      state: { hospital },
    });
  };

  return (
    <>
      {view === "hospitalBooking" && (
        <HospitalBooking onCardClick={handleHospitalCardClick} />
      )}
      {view === "hospitalDetails" && <HospitalDetails />}
      {view === "doctorDetail" && <DoctorDetail />}
      {view === "appointmentDetails" && <AppointmentDetails />}
      {view === "bookingCalendar" && (
        <BookingCalendar
          appointmentType={selectedAppointment}
          hospital={selectedHospital}
          onBack={() => setView("appointmentDetails")}
        />
      )}
    </>
  );
};

export default HospitalPagesNavigate;
