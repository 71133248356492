import React from "react";
import HandleBookingPage from "../../components/booking/handle-booking-page/HandleBookingPage";

function BookingRoute() {
  return (
    <>
      <HandleBookingPage />
    </>
  );
}

export default BookingRoute;
