import React, { useEffect, useState } from "react";
import {
  Card,
  Calendar,
  Radio,
  Space,
  Button,
  Typography,
  message,
} from "antd";
import {
  ClockCircleOutlined,
  CoffeeOutlined,
  MoonOutlined,
  BookOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import usePost from "../../../hooks/usePost";
import axios from "axios";
import useFetch from "../../../hooks/useFetch2";

const { Title, Text } = Typography;

const timePeriods = {
  morning: { start: "08:00", end: "12:00" },
  afternoon: { start: "12:30", end: "17:00" },
  evening: { start: "17:30", end: "22:00" },
};

const BookingCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const [shiftTimes, setShiftTimes] = useState(null);
  const [bookedDates, setBookedDates] = useState([]);
  const [disabledPeriods, setDisabledPeriods] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [isBooked, setIsBooked] = useState([]);
  const today = moment();
  const location = useLocation();
  const doctor = location.state?.doctor;
  const userId = location.state?.userId;
  const hospital = location.state?.hospital;

  const navigate = useNavigate();

  const [bookRes, setBookRes] = useState(null);
  const [bookLoading, setBookLoading] = useState(false);
  const [bookError, setBookError] = useState(null);
  const [fetchBookRes, setFetchBookRes] = useState(null);
  const [fetchBookLoading, setFetchBookLoading] = useState(false);
  const [fetchBookError, setFetchBookError] = useState(null);
  const { post } = usePost("/api/booking", {
    setData: setBookRes,
    setLoading: setBookLoading,
    setError: setBookError,
  });
  const { get } = useFetch("/api/booking", {
    setData: setFetchBookRes,
    setLoading: setFetchBookLoading,
    setError: setFetchBookError,
  });

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    console.log("fetchBookRes=====", fetchBookRes, bookedDates);
    if (fetchBookRes) {
      setBookedDates(fetchBookRes);
      console.log("-----", fetchBookRes.bookingDetail);
      setIsBooked(fetchBookRes);
    } else {
      console.error("fetching booking error");
    }
  }, [fetchBookRes]);

  useEffect(() => {
    if (fetchBookError) {
      console.error("fetchBookError", fetchBookError);
    }
  }, [fetchBookError]);

  useEffect(() => {
    const fetchHolidays = async () => {
      const apiKey = "AIzaSyAde0a0DNQHs6sC66qco3-SrY3Ukk8KqjU";
      const calendarId =
        "en.et.official%23holiday%40group.v.calendar.google.com";
      const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}`;

      try {
        const response = await axios.get(url);
        const holidayDates = response.data.items.map((item) => item.start.date);
        setHolidays(holidayDates);
      } catch (error) {
        console.error("Error fetching holidays:", error);
      }
    };

    fetchHolidays();
  }, []);

  // Fetch bookings by userId
  useEffect(() => {
    const fetchUserBookings = async () => {
      try {
        if (fetchBookRes) {
          console.log("is fetch Book data", fetchBookRes);
          setIsBooked(fetchBookRes);
        }
      } catch (error) {
        console.error("Error fetching user bookings:", error);
      }
    };

    if (userId) {
      fetchUserBookings();
    }
  }, [userId]);

  // Handle date selection
  const handleDateSelect = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setSelectedTimePeriod(null);
    setShiftTimes(null);

    const dayOfWeek = date.format("dddd");
    const shifts = JSON.parse(doctor.shifts);
    const shiftDay = shifts.find((shift) => shift.day === dayOfWeek);
    if (!shiftDay) {
      setDisabledPeriods(["morning", "afternoon", "evening"]);
      return;
    }

    const [shiftStart, shiftEnd] = shiftDay.shift.split(" to ");

    const doctorStart = moment(shiftStart, "HH:mm");
    const doctorEnd = moment(shiftEnd, "HH:mm");
    const disabled = Object.entries(timePeriods)
      .filter(([key, { start, end }]) => {
        const periodStart = moment(start, "HH:mm");
        const periodEnd = moment(end, "HH:mm");
        return (
          (periodStart.isBefore(doctorStart) &&
            periodEnd.isBefore(doctorStart)) ||
          (periodStart.isAfter(doctorEnd) && periodEnd.isAfter(doctorEnd))
        );
      })
      .map(([key]) => key);

    setDisabledPeriods(disabled);
  };

  // Handle time period selection
  const handleTimePeriodChange = (e) => {
    const timePeriod = e.target.value;

    setSelectedTimePeriod(timePeriod);
    if (!selectedDate) return;

    const dayOfWeek = moment(selectedDate).format("dddd");
    const shifts = JSON.parse(doctor.shifts);

    const shiftDay = shifts.find((shift) => shift.day === dayOfWeek);

    if (!shiftDay) return;

    const [shiftStart, shiftEnd] = shiftDay.shift.split(" to ");
    const doctorStart = moment(shiftStart, "HH:mm");
    const doctorEnd = moment(shiftEnd, "HH:mm");

    let { start, end } = timePeriods[timePeriod];
    start = moment(start, "HH:mm");
    end = moment(end, "HH:mm");

    const duration = moment.duration(end.diff(start)).asHours();

    if (duration >= 1) {
      const finalStart = moment.max(start, doctorStart).format("hh:mm A");
      const finalEnd = moment.min(end, doctorEnd).format("hh:mm A");

      console.log("Final Start:", finalStart);
      console.log("Final End:", finalEnd);
      setShiftTimes({ start: finalStart, end: finalEnd });
    } else {
      console.log("Skipping: Time difference is less than 1 hour.");
    }
  };

  // Confirm booking handler
  const handleConfirmBooking = async () => {
    if (selectedDate && selectedTimePeriod && shiftTimes) {
      const bookingData = {
        bookingDetail: `${selectedDate}: ${selectedTimePeriod} - ${shiftTimes.start} to ${shiftTimes.end}`,
        paymentStatus: "pending",
      };
      await post(bookingData);
    } else {
      alert("Please select a date and a time period.");
    }
  };

  useEffect(() => {
    console.log("booked :", bookRes);
    if (bookRes?.status === "success") {
      message.success(bookRes?.message || "Booked Successfully");
      setBookedDates([...bookedDates, selectedDate]);

      setBookLoading(false);
      navigate("/app/pay", {
        state: { doctor, hospital },
      });
    }
  }, [bookRes]);

  useEffect(() => {
    if (bookError) {
      message.error(bookError?.message || "Error on booking");
      setBookLoading(false);
    }
  }, [bookError]);

  const disabledDate = (current) => {
    const formattedDate = current.format("YYYY-MM-DD");
    const isPastDay = current.isBefore(today, "day");
    const shifts = JSON.parse(doctor.shifts);
    const dayOfWeek = current.format("dddd");
    const shiftDay = shifts.find((shift) => {
      return shift.day === dayOfWeek;
    });
    const isWeekend = dayOfWeek === "Saturday" || dayOfWeek === "Sunday";
    const isHoliday = holidays.includes(formattedDate);
    const isNotAvailable = !shiftDay;
    const patientLimit = shiftDay ? shiftDay.patientsPerShift : 0;
    const isLimitExceeded =
      bookedDates.filter((date) => date === formattedDate).length >=
      patientLimit;
    const isUserBooked = isBooked.includes(formattedDate);
    console.log("is user booked?", isBooked.includes(formattedDate));
    return (
      isPastDay ||
      isLimitExceeded ||
      isNotAvailable ||
      isWeekend ||
      isHoliday ||
      isUserBooked
    );
  };

  const dateBookRender = (value) => {
    const dateValue = value;

    if (!dateValue.isValid()) {
      console.log("Invalid date:", value, dateValue);
      return null;
    }

    const formattedDate = dateValue.format("YYYY-MM-DD");
    console.log("------formate", formattedDate, bookedDates);
    console.log(
      "------bookedDates",
      typeof bookedDates?.includes(formattedDate),
      bookedDates?.includes(formattedDate)
    );

    if (bookedDates?.includes(formattedDate)) {
      return <BookOutlined style={{ color: "red", fontSize: "15px" }} />;
    }
    return null;
  };

  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          maxWidth: 600,
          borderRadius: "10px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Title level={4}>
            Dr. {doctor.fullName} - {doctor.specialty}
          </Title>
          <Calendar
            fullscreen={false}
            onSelect={handleDateSelect}
            disabledDate={disabledDate}
            dateCellRender={dateBookRender}
          />

          {selectedDate && (
            <>
              <Title level={5}>
                Select Time Period (international time formatted)
              </Title>

              <Radio.Group
                onChange={handleTimePeriodChange}
                value={selectedTimePeriod}
              >
                <Space direction="vertical">
                  <Radio
                    value="morning"
                    disabled={disabledPeriods.includes("morning")}
                  >
                    <ClockCircleOutlined /> Morning (08:00 AM - 12:00 PM)
                  </Radio>
                  <Radio
                    value="afternoon"
                    disabled={disabledPeriods.includes("afternoon")}
                  >
                    <CoffeeOutlined /> Afternoon (12:30 PM - 05:00PM)
                  </Radio>
                  <Radio
                    value="evening"
                    disabled={disabledPeriods.includes("evening")}
                  >
                    <MoonOutlined /> Evening (05:30 PM - 10:00 PM)
                  </Radio>
                </Space>
              </Radio.Group>
              {console.log("shiftTimes", shiftTimes)}
              {shiftTimes && (
                <div>
                  <Title level={5}>Selected Time Shift</Title>
                  <Text>
                    Start: {shiftTimes.start} - End: {shiftTimes.end}
                  </Text>
                </div>
              )}

              <Button
                type="primary"
                block
                loading={bookLoading}
                onClick={handleConfirmBooking}
                disabled={!selectedDate || !selectedTimePeriod || !shiftTimes}
              >
                Confirm Booking
              </Button>
            </>
          )}
        </Space>
      </Card>
    </div>
  );
};

export default BookingCalendar;
