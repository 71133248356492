import React, { useState, useEffect } from "react";
import {
  Input,
  List,
  Card,
  Rate,
  Spin,
  Modal,
  Space,
  Typography,
  AutoComplete,
  Alert,
  Col,
  Avatar,
} from "antd";
import {
  EnvironmentOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import useFetch from "../../../hooks/useFetch";
import "./HospitalBooking.css";
import { faHospital } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Title, Text } = Typography;
/* Function to calculate the Haversine distance */

function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
}

// Example function to get sorted hospitals by distance
async function getNearestHospitals(userLocation, hospitals) {
  const sortedHospitals = hospitals
    .map((hospital) => {
      const distance = calculateDistance(
        userLocation.lat,
        userLocation.lon,
        hospital.latitude,
        hospital.longitude
      );
      return { ...hospital, distance };
    })
    .sort((a, b) => a.distance - b.distance);

  return sortedHospitals;
}
const HospitalBooking = ({ onCardClick }) => {
  // let userLat, userLon;
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [imageErrors, setImageErrors] = useState({});

  const [localLoading, setLocalLoading] = useState(true); // Added local loading state
  console.log(
    userLocation,
    "....................................................."
  );

  const {
    data: hospitalsData,
    loading,
    getRequest,
  } = useFetch("/api/hospitalForBooking");

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    if (hospitalsData) {
      console.log("hospitalsData .............", hospitalsData.phone);

      setHospitalList(hospitalsData.hospitals);
    }
  }, [hospitalsData]);

  // Ask for user's location permission
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
      },

      (error) => {
        console.error("Location access denied:", error);

        setLocalLoading(false); // Stop loading if location is denied
      }
    );
  }, []);

  useEffect(() => {
    const fetchHospitalsWithLocation = async () => {
      if (!userLocation) return;
      const fetchedHospitals = await Promise.all(
        hospitalList.map(async (hospital, index) => {
          console.log("the profile img----====", hospital.profImg);
          const distance = calculateDistance(
            userLocation.lat,
            userLocation.lon,
            hospital.latitude,
            hospital.longitude
          ).toFixed(2);

          return {
            id: index + 1,
            name: hospital.organizationName || `Hospital ${index + 1}`,
            phone: hospital.phone,
            location: `${distance} km`,
            rating: Math.floor(Math.random() * 5) + 1,
            profImg: hospital.profImg,
            userId: hospital.userId,
          };
        })
      );

      setHospitals(fetchedHospitals);
      setOptions(
        fetchedHospitals.map((hospital) => ({
          value: hospital.name,
        }))
      );
      setLocalLoading(false); // Stop loading after data processing
    };

    if (hospitalList.length > 0) {
      fetchHospitalsWithLocation();
    }
  }, [hospitalList, userLocation]);

  const filteredHospitals = hospitals.filter((hospital) =>
    hospital.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const topRatedHospitals = [...filteredHospitals]
    .sort((a, b) => b.rating - a.rating)
    .slice(0, 5);

  const nearestHospitals = [...filteredHospitals]
    .sort((a, b) => a.distance - b.distance)
    .slice(0, 5);

  const handleSearch = (value) => {
    setOptions(
      hospitals
        .filter((hospital) =>
          hospital.name.toLowerCase().includes(value.toLowerCase())
        )
        .map((hospital) => ({ value: hospital.name }))
    );
  };

  const handleImageError = (key) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [key]: true,
    }));
  };

  return (
    <Space
      id="hospital-booking"
      direction="vertical"
      size="large"
      style={{ width: "100%" }}
    >
      <Card className="autocomplete-container">
        <AutoComplete
          options={options}
          style={{ width: 330 }} // Set a fixed width for AutoComplete
          onSearch={handleSearch}
          onSelect={(value) => {
            const selectedHospital = hospitals.find(
              (hospital) => hospital.name === value
            );
            onCardClick(selectedHospital);
          }}
        >
          <Input.Search
            placeholder="Search hospitals name"
            enterButton={<SearchOutlined />} // Adds search icon as button
            onSearch={(value) => {
              handleSearch(value);
              const selectedHospital = hospitals.find(
                (hospital) =>
                  hospital.name.toLowerCase() === value.toLowerCase()
              );
              if (selectedHospital) onCardClick(selectedHospital);
            }}
          />
        </AutoComplete>
      </Card>

      {loading || localLoading ? ( // Show loading spinner until both loading states are false
        <Col
          style={{
            display: "block",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Spin size="large" />
          <Text
            style={{
              color: "#2d548b",
              display: "block",
              marginTop: "10px",
              fontSize: "30px",
            }}
          >
            Loading Hospitals . . .
          </Text>
        </Col>
      ) : (
        <>
          {/* Top Rated Hospitals */}
          <List
            header={<Title level={4}>Top Rated Hospitals</Title>}
            grid={{ gutter: 16, column: 5, xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
            dataSource={topRatedHospitals}
            renderItem={(hospital) => (
              <List.Item key={hospital.id}>
                <Card
                  hoverable
                  cover={
                    <img
                      src={
                        hospital.profImg && !imageErrors[hospital.id]
                          ? `/file/profile/hospital/${hospital.profImg}`
                          : undefined
                      }
                      style={{ objectFit: "cover" }}
                      onError={() => handleImageError(hospital.id)} // Handle image error
                    />
                  }
                  onClick={() => onCardClick(hospital)}
                >
                  {(!hospital.profImg || imageErrors[hospital.id]) && (
                    <FontAwesomeIcon
                      icon={faHospital}
                      size="10x"
                      style={{ margin: "auto", margin: "2rem" }}
                    />
                  )}
                  <Card.Meta
                    title={hospital.name}
                    description={
                      <div>
                        <EnvironmentOutlined style={{ marginRight: 1 }} />
                        {hospital.location}
                      </div>
                    }
                    style={{ marginBottom: "1rem" }}
                  />
                  <Rate disabled defaultValue={hospital.rating} />
                </Card>
              </List.Item>
            )}
          />
          {/* Nearest Hospitals */}
          <List
            header={<Title level={4}>Nearest Hospitals</Title>}
            grid={{ gutter: 16, column: 5, xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
            dataSource={nearestHospitals}
            renderItem={(hospital) => (
              <List.Item key={hospital.id}>
                <Card
                  hoverable
                  cover={
                    <img
                      src={
                        hospital.profImg && !imageErrors[hospital.id]
                          ? `/file/profile/hospital/${hospital.profImg}`
                          : undefined
                      }
                      style={{ objectFit: "cover" }}
                      onError={() => handleImageError(hospital.id)} // Handle image error
                    />
                  }
                  onClick={() => onCardClick(hospital)}
                >
                  {(!hospital.profImg || imageErrors[hospital.id]) && (
                    <FontAwesomeIcon
                      icon={faHospital}
                      size="10x"
                      style={{ margin: "auto", margin: "2rem" }}
                    />
                  )}
                  <Card.Meta
                    title={hospital.name}
                    description={
                      <div>
                        <EnvironmentOutlined style={{ marginRight: 1 }} />
                        {hospital.location}
                      </div>
                    }
                    style={{ marginBottom: "1rem" }}
                  />
                  <Rate disabled defaultValue={hospital.rating} />
                </Card>
              </List.Item>
            )}
          />
        </>
      )}
    </Space>
  );
};

export default HospitalBooking;
