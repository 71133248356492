import React from "react";
import ConsultingService from "../../components/consulting/consulting-service/ConsultingService";

function ConsultingRoute() {
  return (
    <>
      <ConsultingService />
    </>
  );
}

export default ConsultingRoute;
