import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  Avatar,
  Image,
  Typography,
  Rate,
  Button,
  Row,
  Col,
  Space,
  message,
} from "antd";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useFetch from "../../../hooks/useFetch2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";

const { Title, Text } = Typography;

const ConsultingService = () => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [imageErrors, setImageErrors] = useState({});
  const [doctorList, setDoctorList] = useState([]);
  const [doctorsData, setDoctorsData] = useState([]);
  const [doctorsDataLoading, setDoctorsDataLoading] = useState(false);
  const [doctorsDataError, setDoctorsDataError] = useState(null);
  const navigate = useNavigate();

  const { get: getDoctors } = useFetch("/api/allDoctors", {
    setData: setDoctorsData,
    setLoading: setDoctorsDataLoading,
    setError: setDoctorsDataError,
  });

  // useEffect(() => {
  //   if (!doctor?.phone) {
  //     console.log("phone not found");
  //     return;
  //   }
  //   const phone = doctor.phone;
  //   console.log("phone-------", phone);
  //   if (phone) {
  //     getDoctors(`?phone=${encodeURIComponent(phone)}`);
  //   }
  // }, [doctor]);
  useEffect(() => {
    getDoctors();
  }, []);

  useEffect(() => {
    console.log("doctorsData", doctorsData);
    if (doctorsData?.doctors) {
      setDoctorList(doctorsData.doctors);
    }
  }, [doctorsData]);

  useEffect(() => {
    if (doctorsDataError) {
      console.log("doctorsDataError", doctorsDataError);
      message.error(doctorsDataError.message || "Error fetching doctors data");
    }
  }, []);
  if (!doctorsData) return <p>Loading doctor details...</p>;

  const handleDoctorCardClick = (doctor) => {
    console.log("Selected doctor data:", doctor);
    setSelectedDoctor(doctor);
    navigate(`/app/consulting/doctor-details/${doctor.userId}`, {
      state: { doctor },
    });
  };
  const handleImageError = (doctorId) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [doctorId]: true,
    }));
  };

  return (
    <Card style={{ padding: "20px", margin: "auto", maxWidth: "100%" }}>
      <Title level={2} style={{ textAlign: "center" }}>
        {" "}
        Choose a doctor for consulting service
      </Title>

      <Title level={3}>{doctorList.length} Doctors are available</Title>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 4,
        }}
        dataSource={doctorList}
        renderItem={(doctor) => (
          <List.Item key={doctor.id}>
            <Card
              bordered={false}
              loading={doctorsDataLoading}
              onClick={() => handleDoctorCardClick(doctor)}
              hoverable
              style={{
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "0 4px 16px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow =
                  "0 2px 8px rgba(0, 0, 0, 0.1)";
              }}
            >
              <Space align="center" style={{ width: "100%" }}>
                <Avatar
                  size={70}
                  src={
                    doctor.profImg && !imageErrors[doctor.id]
                      ? `/file/profile/doctor/${doctor.profImg}`
                      : undefined
                  }
                  onError={() => handleImageError(doctor.id)}
                >
                  {(!doctor.profImg || imageErrors[doctor.id]) && (
                    <FontAwesomeIcon icon={faUserMd} size="2x" />
                  )}
                </Avatar>

                <Space direction="vertical" size={0}>
                  <Text strong>{doctor.fullName}</Text>
                  <Text type="secondary">{doctor.specialty}</Text>
                </Space>
              </Space>
            </Card>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default ConsultingService;
