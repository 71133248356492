import React, { useState, useEffect } from "react";
import { Button, Form, InputNumber, message, Modal, Select, Spin } from "antd";
import useFetch from "../../../../hooks/useFetch";
import useFetch2 from "../../../../hooks/useFetch2";
import ShiftDayAndTimeForm from "../../shift-selection/ShiftDayAndTimeForm";
import FormCustomList from "../../../form-list-comp/FormCustomList";

const HospitalAddDoctorsForm = ({
  form,
  shiftForm,
  handleModalOk,
  shiftList,
  setShiftList,
}) => {
  const [searchData, setSearchData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shiftsMap, setShiftsMap] = useState({});

  const [fetchUserLoading, setFetchUserLoading] = useState(false);
  const [fetchUserError, setFetchUserError] = useState(null);
  const [fetchUserData, setFetchUserData] = useState(null);

  const [fetchShiftLoading, setFetchShiftLoading] = useState(false);
  const [fetchShiftError, setFetchShiftError] = useState(null);
  const [fetchShiftData, setFetchShiftData] = useState(null);

  const { data, getRequest } = useFetch("/api/users/search");
  const { get: getShift } = useFetch2("/api/doctor", {
    setLoading: setFetchShiftLoading,
    setError: setFetchShiftError,
    setData: setFetchShiftData,
  });

  useEffect(() => {
    if (data?.data) {
      setSearchData(data?.data);
    }
  }, [data]);

  const handleSearch = async (query) => {
    setSearching(true);
    await getRequest({ role: "doctor", query });
    setSearching(false);
  };

  const handleChange = async (val) => {
    console.log("doctor id:", val);
    const selectedDoctor = searchData.find((user) => user.userId === val);

    if (selectedDoctor) {
      getShift({ userId: selectedDoctor.userId });
      form.setFieldsValue({ fullName: selectedDoctor.fullName });
    }
  };
  useEffect(() => {
    console.log("Updated shiftList:", shiftList);
  }, [shiftList]);

  useEffect(() => {
    console.log("Updated shiftList:", fetchShiftData);
    setShiftList(fetchShiftData?.shifts);
  }, [fetchShiftData]);
  useEffect(() => {
    console.log("Updated error shiftList:", fetchShiftError);
    setShiftList([]);
  }, [fetchShiftError]);

  const handleModalCancel = () => setShowModal(false);

  const handleSelectDate = () => {
    setShowModal(true);
  };
  const handleRemoveShift = (shift) => {
    console.log("shift to remove", shift);
    const updatedShiftList = shiftList.filter((item) => item !== shift);
    setShiftList(updatedShiftList);
    console.log("updatedShiftList", updatedShiftList);
    const doctorId = form.getFieldValue("userId");
    console.log("doctor id", doctorId);

    setShiftsMap((prevMap) => ({
      ...prevMap,
      [doctorId]: updatedShiftList,
    }));
  };
  const handleOnFinish = () => {
    console.log("shiftList data:", shiftList);
    if (shiftList.length === 0) {
      message.error("Please add at least one shift before submitting.");
      return;
    }
    handleModalOk(shiftList);
  };
  return (
    <>
      <Form onFinish={handleOnFinish} form={form} layout="vertical">
        <Form.Item
          label="Doctor"
          name="userId"
          rules={[{ required: true, message: "Please select a doctor!" }]}
        >
          <Select
            showSearch
            onChange={handleChange}
            placeholder="Search and select"
            tokenSeparators={[","]}
            notFoundContent={searching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={handleSearch}
            style={{ width: "100%" }}
          >
            {searchData.map((item) => (
              <Select.Option key={item.userId} value={item.userId}>
                {item.fullName} - {item.phone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={shiftList?.length > 0 && "Shifts"}
          name="shifts"
          help={
            shiftList?.length === 0 ? "Please add at least one shift." : null
          }
        >
          <FormCustomList
            dataSource={shiftList}
            handleRemoveItem={handleRemoveShift}
          />
        </Form.Item>
        <Button
          type="primary"
          onClick={handleSelectDate}
          style={{ marginBottom: 16 }}
        >
          Add Patient Capacity, Shift Day and Time
        </Button>

        <Modal
          title="Select Shift Date and Time"
          open={showModal}
          onCancel={handleModalCancel}
          footer={null}
        >
          <ShiftDayAndTimeForm
            setShiftList={setShiftList}
            shiftList={shiftList}
            onCancel={handleModalCancel}
            shiftForm={shiftForm}
            handleModalOk={handleModalOk}
          />
        </Modal>
      </Form>
    </>
  );
};

export default HospitalAddDoctorsForm;
