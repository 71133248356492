import React, { useState } from "react";
import {
  Select,
  DatePicker,
  Space,
  Button,
  message,
  Form,
  InputNumber,
} from "antd";

const { RangePicker } = DatePicker;

const daysOfWeek = [
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];

const ShiftDayAndTimeForm = ({
  setShiftList,
  shiftList,
  onCancel,
  handleModalOk,
}) => {
  const [shiftForm] = Form.useForm();

  const handleAddShift = async () => {
    console.log("add button clicked");
    try {
      const value = await shiftForm.validateFields();

      setShiftList([...shiftList, value]);
      onCancel();
      shiftForm.resetFields();
    } catch (error) {
      console.log(error);
      if (error?.errorFields) {
        error?.errorFields.forEach((element, index) => {
          if (index < 4) message.error(element.errors[0]);
        });
      }
    }
  };

  return (
    <Form form={shiftForm} layout="vertical" onFinish={handleModalOk}>
      <Space direction="vertical" size={12}>
        <Form.Item
          label="Select Day"
          name="day"
          rules={[
            { required: true, message: "Please select at least one day!" },
          ]}
        >
          <Select
            placeholder="Select day"
            options={daysOfWeek}
            style={{ width: "100%", marginTop: "8px" }}
          />
        </Form.Item>
        <Form.Item
          label="Select Shift (Use international time format)"
          name="shift"
          rules={[{ required: true, message: "Please select shift!" }]}
        >
          <RangePicker use12Hours picker="time" format="HH:mm" />
        </Form.Item>
        <Form.Item
          label="Patients per Shift"
          name="patientsPerShift"
          rules={[
            { required: true, message: "Please insert at least one patient!" },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Space>
          <Button type="primary" onClick={handleAddShift}>
            Add
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      </Space>
    </Form>
  );
};

export default ShiftDayAndTimeForm;
