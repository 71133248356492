import React from "react";
import ConsultingRoute from "../../../routes/router/ConsultingRoute";

function ConsultingPage() {
  return (
    <div>
      <ConsultingRoute />
    </div>
  );
}

export default ConsultingPage;
