import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./DoctorProfile.css";
import { Modal, Form, Card, Button, Upload, message, Switch } from "antd";
import usePatch from "../../../../hooks/usePatch";
import useFetch from "../../../../hooks/useFetch2";
import DoctorEditProfileForm from "../doctor-edit-profile-form/DoctorEditProfileForm";
import {
  CheckOutlined,
  CloseOutlined,
  FileImageOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import useUpload from "../../../../hooks/useUpload";
import usePost from "../../../../hooks/usePost";
import ShiftDayAndTimeForm from "../../shift-selection/ShiftDayAndTimeForm";
import FormCustomList from "../../../form-list-comp/FormCustomList";

const DoctorProfile = () => {
  const [doctorData, setDoctorData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgRes, setProfileImgRes] = useState(null);
  const [profileImgLoading, setProfileImgLoading] = useState(false);
  const [profileImgError, setProfileImgError] = useState(null);
  const [consultingService, setConsultingService] = useState(false);

  const [shiftList, setShiftList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [consultingServiceRes, setConsultingServiceRes] = useState(null);
  const [consultingServiceLoading, setConsultingServiceLoading] =
    useState(false);
  const [consultingServiceError, setConsultingServiceError] = useState(null);
  const [fetchProfileRes, setFetchProfileRes] = useState(null);
  const [fetchProfileLoading, setFetchProfileLoading] = useState(false);
  const [fetchProfileError, setFetchProfileError] = useState(null);

  const [createRes, setCreateRes] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);

  const [fetchShift, setFetchShift] = useState([]);
  const [fetchShiftLoading, setFetchShiftLoading] = useState(false);
  const [fetchShiftError, setFetchShiftError] = useState(null);
  const { post, reset: resetUpload } = usePost("/api/doctor", {
    setData: setCreateRes,
    setLoading: setCreateLoading,
    setError: setCreateError,
  });
  const [shifts, setShifts] = useState(null);
  const [form] = Form.useForm();
  console.log("=======doctor data", doctorData);
  // CRUD hooks
  const { patchData } = usePatch(`/api/users/${doctorData?.id}`);
  const { patchData: patchConsultService } = usePatch(
    `/api/users/${doctorData?.id}/consulting-status`,
    {
      setData: setConsultingServiceRes,
      setLoading: setConsultingServiceLoading,
      setError: setConsultingServiceError,
    }
  );
  const { get } = useFetch("/api/user", {
    setData: setFetchProfileRes,
    setLoading: setFetchProfileLoading,
    setError: setFetchProfileError,
  });
  const { get: getShift } = useFetch("/api/fetch-shifts", {
    setLoading: setFetchShiftLoading,
    setError: setFetchShiftError,
    setData: setFetchShift,
  });

  const { upload: uploadFile } = useUpload("/api/users/upload-Profile", {
    setLoading: setProfileImgLoading,
    setError: setProfileImgError,
    setData: setProfileImgRes,
  });

  const handleEdit = async () => {
    console.log("=======doctor data", doctorData);

    if (doctorData) {
      form.setFieldsValue({
        fullName: doctorData.fullName || "Yasmin A",
        licenseNumber: doctorData.licenseNumber || "1232",
        experience: doctorData.experience || "6 years",
        specialty: doctorData.specialty || "Dentist",
        email: doctorData.email || "y@a.com",
        location: doctorData.location || "New York",
        workplaces: doctorData.workplaces || "New York",
      });
    }
    setIsModalVisible(true);
  };

  useEffect(() => {
    get({ role: "doctor" });
  }, []);

  useEffect(() => {
    getShift();
  }, []);
  useEffect(() => {
    if (fetchProfileRes) setDoctorData(fetchProfileRes?.users);
  }, [fetchProfileRes]);

  useEffect(() => {
    if (fetchProfileError)
      console.error("fetchProfileError", fetchProfileError);
  }, [fetchProfileError]);

  const handleSwitchChange = async (checked) => {
    console.log("shiftList before add:", shiftList);
    const newStatus = checked ? "active" : "inactive";
    setConsultingService(checked);

    try {
      await patchConsultService({
        consultingService: newStatus,
        role: doctorData?.role,
      });
      if (checked) {
        getShift({ userId: doctorData.userId });
      }
    } catch (error) {
      console.error("Failed to update consulting service:", error);
      setConsultingService(!checked);
    }
  };

  useEffect(() => {
    if (fetchShift) {
      console.log("Updated shiftList:", fetchShift);
      setShiftList(fetchShift?.shifts);
    }
  }, [fetchShift]);

  useEffect(() => {
    if (fetchShiftError) {
      console.log("Updated error shiftList:", fetchShiftError);
      setShiftList([]);
    }
  }, [fetchShiftError]);

  useEffect(() => {
    if (consultingService) {
      message.success(`Consulting service is now active`);
    } else {
      message.error(`Consulting service is now inactive`);
    }
  }, [consultingService]);

  useEffect(() => {
    console.log("patchConsultServiceRes", consultingServiceRes);
    if (consultingServiceRes) {
      setConsultingService(consultingServiceRes?.consultingService);
      setConsultingServiceLoading(false);
    }
  }, [consultingServiceRes]);

  useEffect(() => {
    console.error("patchConsultServiceError", consultingServiceError);
    if (consultingServiceError) {
      setConsultingServiceLoading(false);
    }
  }, [consultingServiceError]);

  const handleModalCancel = () => {
    setIsModalVisible(false);

    setShowModal(false);
  };
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      await patchData(values);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadChange = (info) => {
    setProfileImg(info.file);
  };

  const handleUpload = async () => {
    if (!profileImg) {
      message.warning("Please select a profile image to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("userId", doctorData?.userId);
      formData.append("fullName", doctorData?.fullName);
      formData.append("role", doctorData?.role);

      formData.append("ProfileImage", profileImg);
      await uploadFile(formData);
    } catch (error) {
      message.error("Failed to upload profile image.");
      console.error(error);
    }
  };

  useEffect(() => {
    console.log("profileImgRes===", profileImgRes);
    if (profileImgRes?.status === "success") {
      message.success(profileImgRes.message || "Uploaded successfully");

      setDoctorData((prev) => ({
        ...prev,
        profImg: profileImgRes.filename || prev.profImg,
      }));
    }
    setProfileImgLoading(false);
  }, [profileImgRes]);

  useEffect(() => {
    if (profileImgError) {
      message.error(profileImgError || "Failed to upload profile");
      setProfileImgLoading(false);
    }
  }, [profileImgError]);

  const profileImageUrl = doctorData?.profImg
    ? `/file/profile/doctor/${doctorData.profImg}`
    : null;

  const handleAddShift = () => {
    setShowModal(true);
  };
  useEffect(() => {
    console.log("Updated shiftList:", shiftList);
  }, [shiftList]);

  const handleModalShiftOk = async () => {
    try {
      await form.validateFields();

      const values = form.getFieldValue();
      values.shifts = shiftList;

      if (values.shifts.length === 0) {
        message.error("Please add at least one shift");
        return;
      }
      console.log("Received values of form: ", values);

      await post(values);
      setShowModal(false);
      form.resetFields();
      setShiftList([]);
    } catch (error) {
      console.log(error);

      if (error?.errorFields) {
        error?.errorFields.forEach((element, index) => {
          if (index < 4) message.error(element.errors[0]);
        });
      }
    }
  };

  useEffect(() => {
    console.log("doctor shift added:", createRes);
    if (createRes?.status === "success") {
      message.success(createRes?.message || "Doctor Shift Added Successfully");
      getShift();
    }
  }, [createRes]);

  useEffect(() => {
    console.log("doctor shift error:", createError);
    if (createError)
      message.error(createError?.message || "Error Adding Doctor");
  }, [createError]);

  const handleRemoveShift = (shift) => {
    console.log("shift to remove", shift);
    const updatedShiftList = shiftList.filter((item) => item !== shift);
    setShiftList(updatedShiftList);
    console.log("updatedShiftList-----", updatedShiftList);
    const doctorId = form.getFieldValue("userId");
    console.log("doctor id", doctorId);

    setShifts((prevMap) => ({
      ...prevMap,
      [doctorId]: updatedShiftList,
    }));
  };
  return (
    <div id="doctor-profile">
      <div className="container">
        <div className="form-container">
          {doctorData && (
            <Card
              className="doctor-card"
              hoverable
              style={{
                width: 280,
                margin: "auto",
              }}
              cover={
                <>
                  {profileImageUrl ? (
                    <img
                      src={profileImageUrl}
                      alt="Doctor Profile Image"
                      className="profile-image"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  ) : profileImg ? (
                    <img
                      src={URL.createObjectURL(profileImg)}
                      alt="Doctor Profile Image Preview"
                      className="profile-image"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        top: "5px",
                      }}
                    >
                      <Upload
                        name="image"
                        beforeUpload={() => false}
                        onChange={handleUploadChange}
                        listType="picture"
                        showUploadList={false}
                      >
                        <Button
                          style={{
                            justifySelf: "center",
                          }}
                        >
                          <FileImageOutlined /> Select Doctor Image for Profile
                        </Button>
                      </Upload>
                    </div>
                  )}
                </>
              }
            >
              {!profileImageUrl && profileImg && (
                <Button
                  icon={<UploadOutlined />}
                  onClick={handleUpload}
                  loading={profileImgLoading}
                  type="primary"
                >
                  Upload Doctor Image
                </Button>
              )}
              <div className="doctor-info">
                <p>
                  <strong>Full Name:</strong> {doctorData?.fullName}
                </p>
                <p>
                  <strong>License Number:</strong> {doctorData?.licenseNumber}
                </p>
                <p>
                  <strong>Gender:</strong> {doctorData?.sex}
                </p>
                <p>
                  <strong>Experience Year:</strong> {doctorData?.experience}
                </p>
                <p>
                  <strong>Specialty:</strong> {doctorData?.specialty}
                </p>
                <p>
                  <strong>Email:</strong> {doctorData?.email}
                </p>
                <p>
                  {" "}
                  <strong>consulting service:</strong>{" "}
                  <Switch
                    checked={consultingService}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={handleSwitchChange}
                    loading={consultingServiceLoading}
                  />
                </p>
                <>
                  {consultingService && (
                    <>
                      <Button type="primary" onClick={handleAddShift}>
                        {shifts ? "Manage Shift" : "Add Shift"}
                      </Button>
                      <Form.Item
                        label={shiftList?.length > 0 && "Shifts"}
                        name="shifts"
                        help={
                          shiftList?.length === 0
                            ? "Please add at least one shift."
                            : null
                        }
                      >
                        <FormCustomList
                          dataSource={shiftList}
                          handleRemoveItem={handleRemoveShift}
                        />
                      </Form.Item>
                    </>
                  )}
                </>
              </div>
              <Modal
                title="Select Shift Date and Time for consulting service"
                open={showModal}
                onCancel={handleModalCancel}
                footer={null}
                onOk={handleModalShiftOk}
              >
                <ShiftDayAndTimeForm
                  setShiftList={setShiftList}
                  shiftList={shiftList}
                  onCancel={handleModalCancel}
                  handleModalOk={handleModalShiftOk}
                />
              </Modal>
              <div style={{ marginTop: 10 }}>
                <Button onClick={handleEdit}>Edit Profile</Button>
              </div>
              <Modal
                title="Edit Profile"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <DoctorEditProfileForm
                  form={form}
                  handleModalOk={handleModalOk}
                />
                <Form.Item label="Upload Image">
                  <Upload
                    name="image"
                    beforeUpload={() => false}
                    onChange={handleUploadChange}
                    listType="picture"
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
                {!profileImageUrl &&
                  profileImg &&
                  !profileImgRes?.status === "success" && (
                    <Button
                      icon={<UploadOutlined />}
                      onClick={handleUpload}
                      loading={profileImgLoading}
                      type="primary"
                    >
                      Upload Doctor Image
                    </Button>
                  )}
              </Modal>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
