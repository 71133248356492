import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Space,
  Avatar,
  Badge,
  Divider,
  List,
  message,
  Rate,
  Button,
} from "antd";
import VirtualList from "rc-virtual-list";
import useFetch from "../../../hooks/useFetch";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faUserMd } from "@fortawesome/free-solid-svg-icons";
const { Title, Text } = Typography;
const ContainerHeight = 200;

const DoctorDetail = () => {
  const [hospList, setHospList] = useState([]);
  const [imageErrors, setImageErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const doctor = location.state?.doctor || {};
  const hospital = location.state?.hospital || [];
  console.log("===========", doctor);
  console.log("=========== location", location.state);
  const { data: hospitalsData, getRequest } = useFetch("/api/doctor/hospital");

  const {
    userId,
    fullName,
    profImg,
    online,
    specialty,
    licenseNumber,
    rating,
    experience,
    sex,
  } = doctor;

  useEffect(() => {
    if (userId) {
      getRequest({ drId: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (hospitalsData?.data) {
      setHospList(hospitalsData.data);
      console.log(hospitalsData.data, "--------------hospitalData------------");
    }
  }, [hospitalsData]);

  const handleClick = (hospital) => {
    // message.info(`You clicked on ${item.organizationName}`);
    navigate(`/app/booking/hospital-details/${hospital.phone}`, {
      state: { hospital, doctor },
    });
  };
  const handleBookAppointment = () => {
    console.log("Hospital:", hospital);
    console.log("Doctor:", doctor);
    navigate(
      `/app/booking/hospital-details/${hospital.phone}/${doctor.userId}/appointment`,
      {
        state: {
          hospital,
          doctor,
        },
      }
    );
  };
  const handleImageError = (key) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [key]: true,
    }));
  };
  const handleGetConsultingService = () => {
    console.log("consulting service");
  };
  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          maxWidth: 600,
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        }}
        id="doctor-detail"
      >
        {console.log("doctor", doctor)}

        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <div style={{ textAlign: "center" }}>
            <Avatar
              size={100}
              src={
                !imageErrors["doctor"]
                  ? `/file/profile/doctor/${profImg}`
                  : undefined
              }
              onError={() => handleImageError("doctor")}
            >
              {imageErrors["doctor"] && (
                <FontAwesomeIcon icon={faUserMd} size="2x" />
              )}
            </Avatar>
            <Title level={3} style={{ marginTop: 10 }}>
              Dr {fullName}
            </Title>
            <Badge
              status={online ? "success" : "default"}
              text={online ? "Online" : "Offline"}
            />
          </div>

          <Divider />

          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Text>Gender : {sex}</Text>
          </Space>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Text strong>Specialty: {specialty}</Text>
            <Text>License Number: {licenseNumber}</Text>
            <Text>{experience} years experience</Text>
          </Space>

          <Divider />

          {/* Service Places List with Header */}
          <List
            header={<Title level={4}>Service Places</Title>}
            style={{ width: "100%" }}
          >
            <VirtualList
              data={hospList}
              height={ContainerHeight}
              itemHeight={47}
              itemKey="branchNumber"
            >
              {(item) => {
                return (
                  <List.Item
                    key={item.branchNumber}
                    onClick={() => handleClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={50}
                          src={
                            !imageErrors[item.branchNumber]
                              ? `/file/profile/hospital/${item.profImg}`
                              : undefined
                          }
                          onError={() => handleImageError(item.branchNumber)}
                        >
                          {imageErrors[item.branchNumber] && (
                            <FontAwesomeIcon icon={faHospital} size="2x" />
                          )}
                        </Avatar>
                      }
                      title={<h6>{item.organizationName}</h6>}
                      description={`Phone: ${item.phone}`}
                    />
                  </List.Item>
                );
              }}
            </VirtualList>
          </List>

          {/* <Divider /> */}

          <Rate disabled defaultValue={rating} />
          <Button type="primary" block onClick={() => handleBookAppointment()}>
            Book Appointment
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default DoctorDetail;
