import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Radio,
  Button,
  Upload,
  Select,
  Checkbox,
  Space,
  Card,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

const { Option } = Select;

const PaymentForm = ({}) => {
  const [form] = Form.useForm();
  const [isForSomeoneElse, setIsForSomeoneElse] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const { hospital, doctor } = location.state || {};

  const handleFormSubmit = (values) => {
    console.log("Form Values:", values);
  };

  const handleForSomeoneElseChange = (e) => {
    setIsForSomeoneElse(e.target.value === "someoneElse");
  };
  const handleAllergyChecked = () => {
    setChecked(true);
  };
  const handleBack = () => {
    navigate(
      `/app/booking/hospital-details/${hospital?.phone}/${doctor?.userId}/appointment/calender`,
      {
        state: { doctor, hospital },
      }
    );
  };

  return (
    <Card title="Patient Detail" style={{ maxWidth: 350, margin: "30px auto" }}>
      <Form
        form={form}
        layout="vertical"
        style={{ maxWidth: 600, width: "100%" }}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          label="Is this appointment for you or for someone else?"
          name="forWho"
          rules={[{ required: true, message: "Please select an option" }]}
        >
          <Radio.Group onChange={handleForSomeoneElseChange}>
            <Radio value="me">For Me</Radio>
            <Radio value="someoneElse">For Someone Else</Radio>
          </Radio.Group>
        </Form.Item>

        {isForSomeoneElse && (
          <Form.Item
            label="Full Name"
            name="name"
            rules={[{ required: true, message: "Please enter the full name" }]}
          >
            <Input placeholder="Enter full name" />
          </Form.Item>
        )}

        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[{ required: true, message: "Please enter the phone number" }]}
        >
          <Input placeholder="Enter phone number" />
        </Form.Item>

        <Form.Item
          label="Age"
          name="age"
          rules={[{ required: true, message: "Please enter the age" }]}
        >
          <Input placeholder="Enter age" />
        </Form.Item>

        <Form.Item label="Weight (kg)" name="weight">
          <Input placeholder="Enter weight" />
        </Form.Item>

        <Form.Item
          label="Blood Type"
          name="bloodType"
          rules={[{ required: true, message: "Please select a blood type" }]}
        >
          <Select placeholder="Select blood type">
            <Option value="A+">A+</Option>
            <Option value="A-">A-</Option>
            <Option value="B+">B+</Option>
            <Option value="B-">B-</Option>
            <Option value="AB+">AB+</Option>
            <Option value="AB-">AB-</Option>
            <Option value="O+">O+</Option>
            <Option value="O-">O-</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="allergy"
          valuePropName="checked"
          onChange={handleAllergyChecked}
        >
          <Checkbox>Does the patient have any allergies?</Checkbox>
        </Form.Item>
        {checked && (
          <Form.Item label="Upload an Image" name="image">
            <Upload name="image" listType="picture" maxCount={1}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        )}

        <Form.Item
          label="Select Payment Method"
          name="paymentMethod"
          rules={[
            { required: true, message: "Please select a payment method" },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="teleBirr">Tele Birr</Radio>
              <Radio value="chapa">Chapa</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Confirm and Pay
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type="link" onClick={handleBack} block>
            Back to Booking Calendar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default PaymentForm;
