import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./HospitalProfile.css";
import { Modal, Form, Card, Button, Upload, message } from "antd";
import usePatch from "../../../../hooks/usePatch";
import useFetch from "../../../../hooks/useFetch2";
import usePost from "../../../../hooks/usePost";
import HospitalEditProfileForm from "../hospital-edit-profile-form/HospitalEditProfileForm";
import { FileImageOutlined, UploadOutlined } from "@ant-design/icons";
import useUpload from "../../../../hooks/useUpload";

const HospitalProfile = () => {
  const [hospitalData, setHospitalData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fetchProfileRes, setFetchProfileRes] = useState(null);
  const [fetchProfileLoading, setFetchProfileLoading] = useState(false);
  const [fetchProfileError, setFetchProfileError] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgRes, setProfileImgRes] = useState(null);
  const [profileImgLoading, setProfileImgLoading] = useState(false);
  const [profileImgError, setProfileImgError] = useState(null);

  const [form] = Form.useForm();

  // Crud hooks
  const { patchData } = usePatch(`/api/users/${hospitalData?.id}`);
  const { get } = useFetch("/api/user", {
    setData: setFetchProfileRes,
    setLoading: setFetchProfileLoading,
    setError: setFetchProfileError,
  });
  const { upload: uploadFile } = useUpload("/api/users/upload-Profile", {
    setLoading: setProfileImgLoading,
    setError: setProfileImgError,
    setData: setProfileImgRes,
  });

  const handleEdit = async () => {
    console.log("hospital data:", hospitalData);
    if (hospitalData) {
      form.setFieldsValue({
        fullName: hospitalData.fullName,
        userId: hospitalData.userId,
        role: hospitalData.role,
        licenseNumber: hospitalData.licenseNumber,
        experience: hospitalData.experience,
        specialty: hospitalData.specialty,
        email: hospitalData.email,
        location: hospitalData.location,
        workplaces: hospitalData.workplaces,
      });
    }
    setIsModalVisible(true);
  };

  useEffect(() => {
    console.log("fetching...");
    get({ role: "hospital" });
  }, []);

  useEffect(() => {
    if (fetchProfileRes) setHospitalData(fetchProfileRes?.users);
  }, [fetchProfileRes, hospitalData]);

  useEffect(() => {
    if (fetchProfileError)
      console.error("fetchProfileError", fetchProfileError);
  }, [fetchProfileError]);

  const handleModalCancel = () => setIsModalVisible(false);
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      await patchData(values);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpload = async () => {
    if (!profileImg) {
      message.warning("Please select a profile image to upload.");
      return;
    }
    try {
      const profileImgFormData = new FormData();
      profileImgFormData.append("userId", hospitalData?.userId);
      profileImgFormData.append(
        "organizationName",
        hospitalData?.organizationName
      );
      profileImgFormData.append("role", hospitalData?.role);
      profileImgFormData.append("ProfileImage", profileImg);
      await uploadFile(profileImgFormData);
    } catch (error) {
      console.log("error on uploading profile", error);
      if (error?.errorFields) {
        error?.errorFields.forEach((element, index) => {
          if (index < 4) message.error(element.errors[0]);
        });
      }
    }
  };

  useEffect(() => {
    console.log("set Profile Image as:", profileImgRes);
    if (profileImgRes?.status) {
      message.success(profileImgRes.message || "Uploaded successfully");
      // Optionally update hospitalData with the new profImg if returned from the server.
      setHospitalData((prev) => ({
        ...prev,
        profImg: profileImgRes.filename || prev.profImg,
      }));
    } else {
      console.log("profile is not added");
    }
    setProfileImgLoading(false);
  }, [profileImgRes]);

  useEffect(() => {
    console.log("profileImgError", profileImgError);
    if (profileImgError) {
      message.error(profileImgError || "Failed to upload profile");
      console.error("Failed to add profile");
    }
    setProfileImgLoading(false);
  }, [profileImgError]);

  const handleUploadChange = (info) => {
    console.log("Selected file:", info.file);
    setProfileImg(info.file);
  };

  // Build the URL for the already uploaded profile image if available
  const profileImageUrl = hospitalData?.profImg
    ? `/file/profile/hospital/${hospitalData.profImg}`
    : null;

  return (
    <div id="hospital-profile">
      <div className="container">
        <div className="form-container">
          {hospitalData && (
            <Card
              className="hospital-card"
              hoverable
              style={{
                width: 280,
                margin: "auto",
              }}
              cover={
                <>
                  {profileImageUrl ? (
                    <img
                      src={profileImageUrl}
                      alt="Hospital Profile Image"
                      className="profile-image"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  ) : profileImg ? (
                    <img
                      src={URL.createObjectURL(profileImg)}
                      alt="Hospital Profile Image Preview"
                      className="profile-image"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Upload
                      name="image"
                      beforeUpload={() => false}
                      onChange={handleUploadChange}
                      listType="picture"
                      showUploadList={false}
                    >
                      <Button>
                        <FileImageOutlined /> Select Hospital Image for Profile
                      </Button>
                    </Upload>
                  )}
                </>
              }
            >
              {/* Only show the "Upload Hospital Image" button if no image has been uploaded yet */}
              {!profileImageUrl && profileImg && (
                <Button
                  icon={<UploadOutlined />}
                  onClick={handleUpload}
                  loading={profileImgLoading}
                  type="primary"
                >
                  Upload Hospital Image
                </Button>
              )}
              <div className="hospital-info">
                <p>
                  <strong>Hospital Name:</strong>{" "}
                  {hospitalData?.organizationName}
                </p>
                <p>
                  <strong>Address :</strong> {hospitalData?.address}
                </p>
                <p>
                  <strong>Branch Number :</strong> {hospitalData?.branchNumber}
                </p>
                <p>
                  <strong>Email:</strong> {hospitalData?.email}
                </p>
              </div>

              <Button onClick={handleEdit}>Edit Profile</Button>
              <Modal
                title="Edit Post"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <HospitalEditProfileForm
                  form={form}
                  handleModalOk={handleModalOk}
                />
                <Form.Item label="Upload Image">
                  <Upload
                    name="image"
                    onChange={handleUploadChange}
                    listType="picture"
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </Modal>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default HospitalProfile;
