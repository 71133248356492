import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  Image,
  Typography,
  Rate,
  Button,
  Row,
  Col,
  Space,
  message,
  Avatar,
} from "antd";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useFetch from "../../../hooks/useFetch2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faUserMd } from "@fortawesome/free-solid-svg-icons";

const { Title, Text } = Typography;

const HospitalDetails = () => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const [doctorList, setDoctorList] = useState([]);
  const [doctorsData, setDoctorsData] = useState([]);
  const [doctorsDataLoading, setDoctorsDataLoading] = useState(false);
  const [doctorsDataError, setDoctorsDataError] = useState(null);

  const [imageErrors, setImageErrors] = useState({});
  const { hospPhone } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const hospital = location.state?.hospital || {};
  console.log("hospital data:", hospital);
  const { get: getDoctors } = useFetch("/api/doctorInHosp", {
    setData: setDoctorsData,
    setLoading: setDoctorsDataLoading,
    setError: setDoctorsDataError,
  });

  useEffect(() => {
    console.log("the hospital phone is", hospital.phone, hospPhone);
    if (!hospital?.phone && !hospPhone) {
      console.log("phone not found");
      return;
    }
    const phone = hospital.phone;
    console.log("phone-------", phone);
    if (phone) {
      getDoctors(`?phone=${encodeURIComponent(phone)}`);
    }
  }, []);

  useEffect(() => {
    console.log("doctorsData", doctorsData);
    if (doctorsData?.doctors) {
      setDoctorList(doctorsData.doctors);
    }
  }, [doctorsData]);

  useEffect(() => {
    if (doctorsDataError) {
      console.log("doctorsDataError", doctorsDataError);
      message.error(doctorsDataError.message || "Error fetching doctors data");
    }
  }, []);
  if (!hospital) return <p>Loading hospital details...</p>;

  const handleDoctorCardClick = (doctor) => {
    console.log("Selected doctor data:", doctor);
    setSelectedDoctor(doctor);
    navigate(
      `/app/booking/hospital-details/${hospital.phone}/${doctor.userId}`,
      {
        state: { doctor, hospital },
      }
    );
  };

  const handleImageError = (doctorId) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [doctorId]: true,
    }));
  };
  return (
    <Card style={{ padding: "20px", margin: "auto", maxWidth: "100%" }}>
      {console.log("hospital", hospital)}
      <Title level={2} style={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{ imageOrientation: "center" }}
            size={100}
            src={
              hospital.profImg && !imageErrors[hospital.id]
                ? `/file/profile/hospital/${hospital.profImg}`
                : undefined
            }
            onError={() => handleImageError(hospital.id)}
          >
            {(!hospital.profImg || imageErrors[hospital.id]) && (
              <FontAwesomeIcon icon={faHospital} size="2x" />
            )}
          </Avatar>
        </div>
        <strong>
          {hospital.name
            ? hospital.name
            : hospital.organizationName || "Unknown Hospital"}
        </strong>
      </Title>

      <Title level={3}>{doctorList.length} Doctors</Title>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 4,
        }}
        dataSource={doctorList}
        renderItem={(doctor) => (
          <List.Item key={doctor.id}>
            <Card
              bordered={false}
              loading={doctorsDataLoading}
              onClick={() => handleDoctorCardClick(doctor)}
              hoverable
              style={{
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "0 4px 16px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow =
                  "0 2px 8px rgba(0, 0, 0, 0.1)";
              }}
            >
              <Space align="center" style={{ width: "100%" }}>
                <Avatar
                  size={70}
                  src={
                    doctor.profImg && !imageErrors[doctor.id]
                      ? `/file/profile/doctor/${doctor.profImg}`
                      : undefined
                  }
                  onError={() => handleImageError(doctor.id)}
                >
                  {(!doctor.profImg || imageErrors[doctor.id]) && (
                    <FontAwesomeIcon icon={faUserMd} size="2x" />
                  )}
                </Avatar>
                <Space direction="vertical" size={0}>
                  <Text strong>{doctor.fullName}</Text>
                  <Text type="secondary">{doctor.specialty}</Text>
                </Space>
              </Space>
            </Card>
          </List.Item>
        )}
      />

      {/* Services List */}
      <Title level={3}>Services</Title>
      <List
        dataSource={hospital?.services}
        renderItem={(service, index) => (
          <List.Item key={index}>
            <Card
              cover={<Image src={service.image} alt={service.name} />}
              bordered={false}
              hoverable
              style={{
                textAlign: "center",
                cursor: "pointer",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow =
                  "0 4px 16px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow =
                  "0 2px 8px rgba(0, 0, 0, 0.1)";
              }}
            >
              <Title>{service.name}</Title>
              <Text>{service.description}</Text>
            </Card>
          </List.Item>
        )}
      />

      {/* Location and Rating */}
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Title level={4}>Location</Title>
          <Text>{hospital.location}</Text>
        </Col>
        <Col xs={24} sm={12}>
          <Title level={4}>Rating</Title>
          <Rate disabled defaultValue={hospital.rating} />
        </Col>
      </Row>
    </Card>
  );
};

export default HospitalDetails;
